<template>
      <b-row>
        <b-col class="col-12 col-md-6">
          <b-card class="h-90">
            <b-card-header>
                <b-card-title>{{$t('tiktok.value')}}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row class="d-flex flex-wrap" v-if="Object.keys(blogger_prices).length > 0">
                <!-- STAND BY CALCULATE OUR OWN PRICES -->
                <b-col v-if="isSuperUser()">
                    <strong>
                        {{$t('tiktok.estimated_price')}}
                    <b-icon font-scale="1" icon="question-circle" class="icon-tooltip" id="engagement" />
                    <b-tooltip
                        target="engagement"
                        triggers="hover"
                        variant="primary"
                    >
                       {{$t('tiktok.estimated_price_tooltip')}}
                    </b-tooltip>
                    <p class="h3 mt-1">${{separatebycomma(blogger_prices.post_price_from)}}-{{separatebycomma(blogger_prices.post_price_to)}}</p>
                    </strong>
                </b-col>
                <!-- ------------------------------ -->

                <b-col>
                    <strong>
                        {{$t('search.account')}} CPM
                    <b-icon font-scale="1" icon="question-circle" class="icon-tooltip" id="cpm" />
                    <b-tooltip
                        target="cpm"
                        triggers="hover"
                        variant="primary"
                    >
                        {{$t('tiktok.account_cpm_tooltip')}}
                    </b-tooltip>
                    <div class="d-flex align-items-end">
                        <div class="mt-1 h3 m-0 d-flex align-items-center">
                        <span :class="`dot-profile-tiktok ${getColorDotProfile(blogger_prices.cpm_mark)}`"/> 
                        <span class="mr-1">${{decimalFixed(blogger_prices.cpm_from, 2)}} -   ${{decimalFixed(blogger_prices.cpm_to, 2)}}</span>
                        </div>
                        <span>{{$t(`tiktok.${blogger_prices.cpm_mark}`)}}</span>
                    </div>
                    </strong>
                </b-col>
              </b-row>
              <div class="text-muted" v-else>
                {{$t('tiktok.no_data_sorry')}}
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col class="col-12d col-md-6" v-if="audience_sentiments[0] !== false">
          <b-card class="h-90">
            <b-card-header>
              <b-card-title>{{$t('tiktok.analysis_sentiment')}}</b-card-title>
            </b-card-header>
            <b-card-body>
              <div v-if="audience_sentiments.data !== null">
                <b-row class="d-flex align-items-center">
                  <b-col class="col-12 col-xl mb-2">
                  <component :is="apex_charts" height="150" :options="audienceSentimentsOptions" :series="audienceSentimentsSerie" />
                  </b-col>
                  <b-col class="border-right text-center">
                    <p>{{$t('tiktok.comments_positives')}}</p>
                    <p class="d-flex align-items-center justify-content-center">
                      <span 
                        :class="`dot-profile-tiktok
                        ${getColorDotProfile('good')}`"
                      />
                      <strong class="mr-1">{{audience_sentiments.data.sentiments.POSITIVE.prc}} %</strong>
                      {{getFormat(audience_sentiments.data.sentiments.POSITIVE.count)}}
                    </p>

                  </b-col>
                  <b-col class="border-right text-center">
                    <p>{{$t('tiktok.comments_negatives')}}</p>
                    <p class="d-flex align-items-center justify-content-center">
                      <span 
                        :class="`dot-profile-tiktok
                        ${getColorDotProfile('low')}`"
                      />
                      <strong class="mr-1">{{audience_sentiments.data.sentiments.NEGATIVE.prc}} %</strong>
                      {{getFormat(audience_sentiments.data.sentiments.NEGATIVE.count)}}
                    </p>
                  </b-col>
                  <b-col class="text-center">
                    <p>{{$t('tiktok.comments_neutral')}}</p>
                    <p class="d-flex align-items-center justify-content-center">
                      <span 
                        :class="`dot-profile-tiktok
                        ${getColorDotProfile('none')}`"
                      />
                      <strong class="mr-1">{{audience_sentiments.data.sentiments.NEUTRAL.prc}} %</strong>
                      {{getFormat(audience_sentiments.data.sentiments.NEUTRAL.count)}}
                    </p>
                  </b-col>

                </b-row>
              </div>
              <div v-else class="text-muted">
                  {{$t('tiktok.not_analysis_sentiment')}}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        
        <b-col class="col-12 col-md-6" v-if="blogger_challenges_performance[0] !== false">
          <b-card>
            <b-card-header>
              <b-card-title>Hashtags</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row class="d-flex flex-wrap" v-if="getDataHashtags() !== null">
                <b-col class="border-bottom d-flex justify-content-between py-1 font-weight-bolder h5 header-table-hashtags">
                  <div>Hashtag</div>
                  <div>Engagement</div>
                </b-col>
                <b-col class="col-12 d-flex justify-content-between py-1 border-bottom" v-for="(hashtag, index) in getDataHashtags()" :key="index">
                    <p class="m-0"><span class="text-muted">{{index + 1}} </span><strong class="mx-1">#{{hashtag.challenge}}</strong> <span class="text-muted">{{hashtag.posts_count}} posts</span> </p>
                    <p class="m-0 text-muted">{{decimalFixed(hashtag.er, 2)}} %</p>
                </b-col>
              </b-row>
              <b-row v-else class="text-muted pl-1">
                {{$t('tiktok.no_hashstags')}}
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col class="col-12 col-md-6" v-if="likes_distribution[0] !== false">
          <b-card>
            <b-card-header >
              <b-card-title>{{$t('tiktok.distribution_like')}}</b-card-title>
            </b-card-header>
            <b-card-body>
                <b-row  v-if="getSeriesLikesDistribution() !== null">
                  <b-col>
                    <component :is="apex_charts" type="bar" height="400" :options="likesDistributionOptions" :series="LikesDistributionsSeries"></component>
                  </b-col>
                </b-row>
                <b-row v-else class="text-muted pl-1">
                {{$t('tiktok.no_distribution_like')}}
                </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col class="col-12" v-if="blogger_views_likes_chart[0] !== false">
          <b-card>
            <b-card-header>
              <b-card-title>{{$t('tiktok.difuzzion')}}</b-card-title>
            </b-card-header>
            <b-card-body>
                <b-row class="d-flex flex-wrap">
                  <b-col>
                    <component :is="apex_charts" type="scatter" height="350" :options="chart_options_scatter" :series="series_scatter" :key="show"/>
                  </b-col>
                </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BTooltip } from 'bootstrap-vue';
import { getColorDotProfile } from '@/libs/utils/series_graphs';
import { decimalFixed, getFormat, separatebycomma } from '@/libs/utils/formats';
import { getUserData } from '@/libs/utils/user';
import service from '@/services/profile';

export default {
  name: 'featuresTwo',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTooltip,
  },
  props: {
    username: {
      type: String,
    },
    blogger_prices: {
      type: Object,
      default: () => {}
    },
    blogger_challenges_performance: {
      type: Object,
      default: () => [false]
    },
    likes_distribution: {
      type: Object,
      default: () => [false]
    },
    blogger_views_likes_chart: {
      type: Array,
      default: () => [false]
    },
    channel_id: {
      type: String,
      required: true
    },
    audience_sentiments: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      decimalFixed,
      getFormat,
      getColorDotProfile,
      separatebycomma,
      metrics_videos_scatter: [],
      series_scatter: [],
      chart_options_scatter: {},
      show: false,
      apex_charts: null,
    }
  },
  created() {
    this.getMetricsPostScatter()
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  methods: {
    getSeriesLikesDistribution() {
      const data = Object.values(Object.values(this.likes_distribution)[1].value)
      if (data.filter((item) => item > 0).length === 0)
        return null
      const divider = 100 / Math.max.apply(null, data);
      return data.map((item) => item * divider) 
    },
    getCategoriesLikesDistribution() {
      return Object.keys(Object.values(this.likes_distribution)[1].value)
    },
    getDataHashtags() {
      return Object.values(this.blogger_challenges_performance).map((item) => item.basic_stats)[1];
    },
    isSuperUser() {
      return getUserData().is_superuser
    },
    getMetricsPostScatter() {
      service.getMetricsVideosTiktok(this.channel_id).then((response) => {
        this.metrics_videos_scatter = response.media;
        this.series_scatter = this.bloggerViewsLikesChartSeries(),
        this.chart_options_scatter = this.bloggerViewsLikesChartOptions()
      })
    },
    getSeriesScatter() {
      const not_recommended = this.blogger_views_likes_chart.filter((item) => item.is_recommended === false);
      const recommended = this.blogger_views_likes_chart.filter((item) => item.is_recommended === true);

      const not_recommendedSeries = not_recommended.map((item) => {
        const metrics = this.metrics_videos_scatter && this.metrics_videos_scatter[item.id] ? this.metrics_videos_scatter[item.id].metrics : null;
        return [item.x, item.y, item.thumbnail, metrics];
      });

      const recommendedSeries = recommended.map((item) => {
        const metrics = this.metrics_videos_scatter && this.metrics_videos_scatter[item.id] ? this.metrics_videos_scatter[item.id].metrics : null;
        return [item.x, item.y, item.thumbnail, metrics];
      });

      return [not_recommendedSeries, recommendedSeries];
    },
    bloggerViewsLikesChartSeries() {
      return [
        {
          name: this.$t('tiktok.not_recommend'),
          data: this.getSeriesScatter()[0]
        },
        {
          name: this.$t('tiktok.recommend'),
          data: this.getSeriesScatter()[1]
        },
      ]
    },
    bloggerViewsLikesChartOptions() {
      return {
        chart: {
          height: 350,
          type: "scatter",
          zoom: {
            enabled: true,
            type: "xy"
          },
          toolbar: {
            show: false
          },
        },
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph-difuzion">
                  <div class="p-1 d-flex">
                    <div> 
                      <img src="${w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]}" class="tooltip-dots-img"/>
                    </div>
                    <div>
                      <p class="mr-tooltip-graph">
                        <strong>
                            ${getFormat(w.globals.initialSeries[seriesIndex].data[dataPointIndex][3].views_count.value)} ${this.$t('youtube.visualizations')}
                        </strong>
                      </p>
                      <p class="mr-tooltip-graph">
                        <strong>
                            ${getFormat(w.globals.initialSeries[seriesIndex].data[dataPointIndex][3].likes_count.value)} Likes
                        </strong>
                      </p>
                      <p class="mr-tooltip-graph">
                        <strong>
                            ${w.globals.initialSeries[seriesIndex].data[dataPointIndex][3].er.value.toFixed(2)}
                        </strong>
                         % Engagement
                      </p>
                    </div>

                  </div>
                </div>
              `
            )
          }
        },

        xaxis: {
          tickAmount: 10,
          labels: {
            formatter: (val) => {
              return getFormat(val);
            }
          }
        },
        yaxis: {
          tickAmount: 7
        }
      }
    }
  },
  computed: {
    likesDistributionOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#7E4DCD',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '15%',
            endingShape: 'rounded',
            colors: {
              backgroundBarColors: ['#E1E7F0', '#E1E7F0', '#E1E7F0', '#E1E7F0', '#E1E7F0']
            },
            dataLabels: {
              position: 'top',
            }
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -15,
          formatter: (value) => {
            return `${decimalFixed(value, 2)}%` 
          },
          style: {
            fontSize: '13px',
            colors: ['gray']
          }
        },
        yaxis: {
          max: 100,
        },
        xaxis: {
          categories: this.getCategoriesLikesDistribution(),
          show: true,
          labels: {
            show: false
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${decimalFixed(val, 2)}%`
            }
          }
        }
      }
      return options
    },
    LikesDistributionsSeries() {
      const series = [
        {
          data: this.getSeriesLikesDistribution(),
        }
      ]
      return series
    },
    audienceSentimentsOptions() {
      return {
        labels: [this.$t('stats.neutral'), this.$t('stats.negative'), this.$t('stats.positive')],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: ((val) => {
              return `${val}%`
            })
          }
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: ['#A0AEC0', '#E24928', '#27AE60'],
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    return `${parseInt(val)}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: `Positivo`,
                  formatter: (val) => { return `${Math.trunc(val.globals.series[2])}%` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1580,
            options: {
              chart: {
                height: 130,
              },
            },
          },
          {
            breakpoint: 1199,
            options: {
              chart: {
                height: 150,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      }
    },
    audienceSentimentsSerie() {
      return Object.values(this.audience_sentiments.data.sentiments).map((item) => item.prc)
    }
  },
  watch: {
    "$i18n.locale"() {
      this.show = !this.show;
      this.series_scatter = this.bloggerViewsLikesChartSeries()
    

    },
  },
}
</script>
<style>
.h-90 {
  height: 90%;
}
.tooltip-dots-img {
  width: 100px;
  height: 130px;
  object-fit: cover;
  margin-right: 1em;
  
}
.width-tooltip-graph-difuzion {
  width: 300px;
  white-space: normal;
}
.header-table-hashtags {
  background-color: rgb(236, 236, 236);
}
</style>